import { Injectable } from '@angular/core';
import Backendless from 'backendless';
import { User, UserType } from '../../models/remote';
import Utilities from '../../helpers/utilities';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _userToken: string;
  private _currentUser: User;

  constructor() { }

  private processUserResponse(user: User): Promise<User | any> {
    if(UserType.Driver !== user.type){
        this.logout();
        throw {message : 'Esta cuenta no está asociada a un conductor'};
    }
    else {
        console.log('Pasé')
        this._userToken = user['user-token'];

        delete user['user-token'];
        this._currentUser = user;
        return Promise.resolve(user);
    }
}

public login(identity: string, password: string, stayLoggedIn: boolean = true): Promise<User | any> {
    return Backendless.UserService.login(identity, password, stayLoggedIn).then((user: User) => {
        return this.processUserResponse(user);
    }).catch(error => {
        throw Utilities.getTranslatedBackendlessError(error);
    });
}

public changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return this.getCurrentUser().then((user: User) => {
        if (!user) {
            throw {message: 'Usuario no identificado. Cierra la app e inicia sesión de nuevo.'};
        }

        if (user.socialAccount !== 'BACKENDLESS') {
            throw {message: 'Tu tipo de cuenta no puede cambiar su contraseña.'};
        }

        // Also a persistent login because previous one is dismissed
        return Backendless.UserService.logout().then(() => {
            return this.login(user.email, currentPassword, true);
        }).then((_user: Backendless.User) => {
            _user.password = newPassword;
            return Backendless.UserService.update(_user);
        });
    });
}

public logout(): Promise<void> {
    // The invoker is responsible for navigating the user back to Public pages
    const logoutPromises = [Backendless.UserService.logout()];

    return Promise.all(logoutPromises).then(() => {
        this._userToken = null;
        this._currentUser = null;
    });
}

public register(identity: string, password: string, name: string): Promise<boolean> {
    return Backendless.UserService.register({email: identity, password, name}).then((response: any) => {
        // The registration requires email confirmation
        return response.userStatus === 'EMAIL_CONFIRMATION_PENDING';
    });
}

public resetPassword(identity: string): Promise<any> {
    return Backendless.UserService.restorePassword(identity);
}

public resendConfirmationEmail(email: string): Promise<any> {
    return (Backendless as any).Users.resendEmailConfirmation(email);
}

public isAuthenticated(): Promise<boolean> {
    // Verify Backendless session token
    return Backendless.UserService.isValidLogin().then(tokenIsValid => {
        console.log('[ TD:AuthService ] Login token validity:', tokenIsValid);
        // If token is valid, make sure it's also valid for Facebook (if applies).
        if (!tokenIsValid) {
            return this.logout().then(() => false);
        }
        return tokenIsValid;
    }).catch(error => {
        console.warn('[ TD:AuthService ] Could not validate token ', error);
        return this.logout().then(() => false);
    });
}

public getCurrentUser(): Promise<User | Backendless.User | any> {
    return this._currentUser
        ? Promise.resolve(this._currentUser)
        : Backendless.UserService.getCurrentUser().then((user: User) => {
            this._currentUser = user;
            return user;
        });
}
}
