import { Coordinate } from './local';
/**
 *  This file contains models of the entities stored in Backendless tables.
 **/
import Backendless from 'backendless';

export interface BackendlessObject {
    objectId?: string;
    ownerId?: string;
    created?: Date | any;
    updated?: Date | any;
}

export interface User extends BackendlessObject, Backendless.User {
    name: string
    email: string
    type: UserType
    mobilePhoneNumber: string
    driverProfile: DriverProfile
    isUserEnabled: boolean
    passengerProfile: PassengerProfiles
    lastLogin: Date
    socialAccount: AccountType
    userStatus: AccountStatus
}

export interface DriverProfile extends BackendlessObject {
    address: string
    curp: string
    isActive: boolean
    licenseValidity: Date
    rating: number
}

export interface PassengerProfiles extends BackendlessObject {
    rating: number
}

export interface TripRequest extends BackendlessObject {
    driverObjectId: string;
    tripObjectId: string;
}

export interface Trip extends BackendlessObject {
    assigned: Date;
    pickupLocation: Backendless.Data.Point;
    cancelledByDriver: Date;
    cancelledByPassenger: Date;
    cancelledReason: string;
    destinationAddress: string;
    destinationLocation: Backendless.Data.Point;
    driver: User;
    passenger: User;
    ended: Date;
    extraAddedByDriver: boolean;
    extras: Extra[];
    finalSuggestedPrice: number;
    ignorePunishment: boolean;
    initialSuggestedPrice: number;
    isExpress: boolean;
    pickedPassenger: Date;
    pickupAddress: string;
    ratingByDriver: number;
    ratingByPassenger: number;
    route: Backendless.Data.Point[];
    status: number;
    waitedPassenger: Date;
}

export interface DriverProfile extends BackendlessObject {
    address: string;
    curp: string;
    curpFile: string;
    licenseFile: string;
    licenseValidity: Date;
    picture: string;
    rating: number;
  }

export interface Taxi extends BackendlessObject {
    brand: string;
    concessionNumber: string;
    //driver?: User;
    imei: string;
    insuranceCompany: string;
    insuranceFile: string;
    insuranceValidity: Date;
    isTaxiEnabled: boolean;
    model: string;
    year: number;
    plate: string;
    picture: string;
    policyNumber: string;
    //taxiConcessionaire: TaxiConcessionaire;
    //taxiOwner: TaxiOwner;
    qrFile: string;
}

export interface Extra extends BackendlessObject {
    name: string
    cost: number
}

export interface Notification extends BackendlessObject {
    read: boolean;
    //actionType: NotificationActionType;
    sender: User;
    recipient: User;
}

export interface CancellationReasons extends BackendlessObject {
    name: string;
}

export interface CerberusData extends BackendlessObject {
    token: string;
}

export enum UserType {Admin = 'ADMIN', Passenger = 'PASSENGER', Driver = 'DRIVER'}

export enum AccountType {Backendless = 'BACKENDLESS', Facebook = 'FACEBOOK'}

export enum AccountStatus {EmailConfirmationPending = 'EMAIL_CONFIRMATION_PENDING', Enabled = 'ENABLED', Disabled = 'DISABLED'}
