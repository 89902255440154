// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendless: {
    APP_ID: 'C7995537-0939-B3DA-FF5F-8085D620D800',
    API_KEY: '58FE032C-24C2-42FE-B388-D3159DEA7970',
    PICTURES_PATH: {
      TEMP: 'appDirectory/temp'
    }
  },
  streetPassenger: {
    id: 'A21CCC60-D2C3-4A01-97C3-4B995E082FB5',
    name: 'Pasajero de Calle'
  },
  googleMaps: {
    API_KEY: 'AIzaSyDsugWKVU2ayJyOXj5AMsus7MLeRxBgbpA'
  },
  appApi: {
    host: 'https://app.cerberusgps.com'
  },
  api: {
    host: 'https://cerberusgps.com'
  },
  dataToken: {
    email: 'test@cerberusgps.com',
    password: 'sistemas.1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
